import React, { useState, useEffect, useContext } from "react";
import { navigate } from "gatsby";
import { getSession } from "@wvs-org/auth/client";

const defaultContextObject = {
  isAuthenticated: null,
  loading: true,
  loginWithRedirect: null,
  logout: null,
  user: null,
};

export const AuthContext = React.createContext(defaultContextObject);
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const session = JSON.parse(getSession());
    const sessionExpired = new Date(session?.expiresAt) < new Date();

    setLoading(!!session?.isLoadingSession);
    setIsAuthenticated(!!session?.isAuth && !sessionExpired);
    setUser(session?.user);
  }, []);

  const loginWithRedirect = () => {
    window.location.replace(
      `${
        process.env.GATSBY_AUTH_SERVER_URL
      }/login?callbackUrl=${encodeURIComponent(
        `${process.env.GATSBY_API_BASE_URL}/authenticate?currentPage=${window.location.pathname}`
      )}`
    );
  };

  const logout = () => {
    navigate("/logout");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        loginWithRedirect,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
